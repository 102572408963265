import React from 'react';
import styled from '@emotion/styled';
import { graphql } from 'gatsby';

import PageSEO from '@zd/components/page-seo';
import { Container, Row, Col } from '@zd/components/grid';
import LazyImage from '@zd/components/lazy-image';
import PageTitle from '@zd/components/page-title';
import BackLink from '@zd/components/back-link';
import theme from '@zd/theme';

const ImageDiv = styled.div`
  width: 100%;
  flex-grow: 0;
  flex-shrink: 0;
  margin-bottom: 2rem;

  ${theme.medias.gteLarge} {
    position: sticky;
    top: calc(${theme.variables.navbarDesktopHeight} + 2rem);
    margin-bottom: 0;
  }
`;

const StyledLazyImage = styled(LazyImage)`
  width: 100%;
  margin: 0;
  height: 300px;
  border-radius: 20px;
  border: 1px solid ${theme.colors.itemBorder};
  background: ${theme.colors.itemBorder};

  & img {
    object-fit: cover !important;
    object-position: top center !important;
  }

  ${theme.medias.gteLarge} {
    height: auto;
  }
`;

const DescriptionP = styled.p`
  color: ${theme.colors.mainGrey};
  font-size: 18px;
  letter-spacing: 0;
  line-height: 32px;
`;

const StyledBackLink = styled(BackLink)`
  margin-top: 2.5rem;
  margin-bottom: 1.5rem;
`;

const metaTitle = 'Sobre o Dr. João Vilas Boas';
const metaDescription =
  'Muitas pessoas experimentam alguma vez na vida um pensamento de tipo obsessivo ou comportamento compulsivo, mas, no caso da Perturbação obsessivo-compulsiva estes são intensos, persistentes, desagradáveis e interferem com as actividades diárias da pessoa.';

export const query = graphql`
  query {
    topImage: file(relativePath: { eq: "dr-joao-vilas-boas-03.jpeg" }) {
      childImageSharp {
        fluid(maxHeight: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

export default props => {
  return (
    <>
      <PageSEO
        {...props}
        title={metaTitle}
        description={metaDescription}
        thumbnail={props.data.topImage.childImageSharp.fluid.src}
      />
      <Container>
        <Row>
          <Col lg="4">
            <ImageDiv>
              <StyledLazyImage
                fluid={props.data.topImage.childImageSharp.fluid}
                alt="Dr. João Vilas Boas"
                title="Dr. João Vilas Boas"
              />
            </ImageDiv>
          </Col>
          <Col lg="8">
            <PageTitle title="Sobre o Dr. João Vilas Boas" />
            <DescriptionP>
              É sempre redutor colocar em palavras o que pensamos, sentimos e desejamos,
              especialmente numa circunstância de tal importância, em que a concretização deste
              sonho, de ajudar cada um a ser a melhor versão de si mesmo, depende parcialmente da
              capacidade das minhas palavras transmitirem o que penso e sinto.
              <br />
              <br />
              Com 18 anos, quando entrei na Faculdade de Medicina do Porto, queria ser Psiquiatra e
              Psicanalista. Muito cedo me apercebi que, para mim, não há́ nada mais fascinante do que
              o funcionamento da mente humana e todo o seu potencial.
              <br />
              <br />
              Assim, Psiquiatria seria a minha escolha óbvia. Seria a escolha que me permite
              penetrar cada vez mais nas profundezas do admirável aparelho mental, e compreender os
              meandros da vida psíquica subjectiva de cada um, e, ao mesmo tempo, tão comum a toda a
              espécie.
              <br />
              <br />
              Na minha experiência como médico, em Psiquiatria, muitas vezes me vi diante do triste
              poço da existência humana, do buraco negro, confrontado com o desejo de fazer algo que
              fosse além de resgatar alguém da experiência do vazio. Apenas para o abandonar logo
              depois, para o deixar de seguida desamparado, à mercê apenas de psicofármacos,
              depositando as suas esperanças numa posologia, quando a necessidade de ser
              compreendido é tão premente.
              <br />
              <br />
              Acredito que os fármacos devem actuar apenas, quando a palavra falha, e mesmo nesse
              momento complementá-la, e não substituí-la. Devem actuar, como ferramentas de alívio
              sintomático, não substituindo a necessidade da cura, pela compreensão da etiologia, a
              relação.
              <br />
              <br />
              No entanto, num Universo em que tantos sucumbem e se perdem nos meandros da vida
              mental, há tantos outros que prosperam. Sucesso, excelência cognitiva e emocional, são
              objetivos que nos fascinam, mas que poucos conseguem entender e ainda menos alcançar.
              <br />
              <br />
              É verdade, que a Medicina permite um sentimento de altruísmo, como poucas outras
              profissões, ao conter na sua prática a devolução da saúde àquele que está doente.
              <br />
              <br />
              No entanto, se a saúde mental é o ponto de partida para uma fruição plena da vida,
              porquê ficar por aí?
              <br />
              <br />
              Assim, além da devolução da saúde aos meus pacientes, cada vez mais me interessa
              ajudá-los a alcançar o maior desempenho mental, que o seu potencial permite. Como um
              treinador que prepara atletas.
              <br />
              <br />
              Foi assim, que decidi começar, o meu Doutoramento em Psicologia, com o objectivo de
              estudar performance cognitiva e emocional, de topo.
              <br />
              <br />
              As consultas de Psiquiatria ou Psicologia clínica, servem para ajudar alguém, que está
              num estado de doença ou prejuízo funcional a regressar ao seu estado normativo.
              <br />
              <br />
              O ‘Peak Performance’, como o entendo, é um instrumento na relação, que possibilita
              ajudar alguém a alcançar o seu máximo potencial. É como ter um ‘personal trainer’,
              para o desempenho da mente. Para ajudar a alcançar um estado de excelência emocional e
              cognitiva.
              <br />
              <br />
              Trata-se de perceber, quais são as limitações que se devem ultrapassar, e quais os
              aspectos que se devem muscular, para que cada um alcance o seu máximo potencial.
              <br />
              <br />
              Identificar bem as limitações enraizadas na mente, (os modelos mnésicos internos),
              devidas a experiências passadas e não permitir que elas actuem tanto em cada um.
              <br />
              <br />
              Trata-se de questionar as crenças limitantes, para que estas não actuem tanto sobre
              cada um, e cada um possa atingir o seu máximo output, para o colocar, no caminho que
              deseja, rumo aos seus objectivos.
              <br />
              <br />
              O que me apaixona verdadeiramente, é ajudar pessoas, a desbloquearem as suas
              limitações e a realizarem o seu potencial. No fundo, a serem a melhor versão de si
              mesmas! Só podemos evoluir, quando aumentamos o nosso autoconhecimento.
              <br />
              <br />
              Sou fascinado por esta busca que leva o humano a distinguir-se num ou mais campos, a
              ponto de tocar o auge da sua realização e ser um exemplo fulgido para todos que cruzam
              o seu caminho.
              <br />
              <br />
              O meu objetivo, é investigar, estudar, criar conhecimento e fazer ciência. Aprender o
              código do ‘genoma’ do sucesso, na procura da descodificação possível para posterior
              re/construção. Conhecer os traços de personalidade e os processos psicológicos,
              cognitivos e emocionais, que se entrelaçam, e, que mais pesam no curso que leva cada
              um, à sua realização individual.
              <br />
              <br />
              Propus-me assim, a desenvolver um estudo que possa ser benéfico a todos, não apenas às
              pessoas que sofrem de uma doença mental. Compreendendo os mecanismos, que mais
              impulsionam a probabilidade de sucesso e mitigam a probabilidade de insucesso do
              sujeito; através de um processo de engenharia reversa, propus um método para replicar
              os catalisadores que levaram ao desenvolvimento destas competências de excelência.
              Para que todos, possam ser uma melhor versão de si mesmos.
              <br />
              <br />
              Imaginei a possibilidade de uma pesquisa sobre os requisitos psicológicos, para o
              sucesso, e todas as ramificações que condicionam na psique, analisando exemplos
              brilhantes dessa conquista, nos mais diferentes campos.
              <br />
              <br />
              É um trabalho, que beneficiará todos que estudam, ou desejam embarcar nessa
              extraordinária viagem, ousar a aventura, que é o desenvolvimento pessoal.
              <br />
              <br />
              Aprendi que só me faz sentido trabalhar numa área que me apaixone. Tendo tudo dito,
              coloco-me à vossa disposição, com a esperança de que comecemos a trabalhar juntos, na
              sua autodescoberta, em breve. Estou entusiasmado em conhecê-lo. Obrigado.
            </DescriptionP>
            <StyledBackLink />
          </Col>
        </Row>
        <br />
      </Container>
    </>
  );
};
